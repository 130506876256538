import { AxiosError } from 'axios';
import { request } from '../../../utils/config/axios';
import { User } from '../../../utils/interfaces/user';

import { decryptData, encryptData } from '../../../utils/functions/decript';

export interface LoginData {
	email: string;
	password: string;
}

export const initialUserState: User = {
	_id: '',
	email: '',
	role: ''
};

async function loginUser(params: LoginData): Promise<{ user: User; message: string } | string> {
	try {
		const encParams = encryptData(params);
		const encryptedResponse = await request.post('auth/login', encParams);
		const data = decryptData(encryptedResponse) as {admin: User};
		return {
			user: { _id: data?.admin._id, email: data?.admin?.email, role: data?.admin?.role},
			message: 'User signed in successfully!',
		};
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Failed to login.');
	}
}

async function logoutUser(): Promise<string> {
	try {
		const res = await request.post('auth/logout');
		const data = res.data as unknown as { message: string };
		return data.message;
	} catch (e) {
		const error = e as unknown as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Failed to logout.');
	}
}

export { loginUser, logoutUser };
