import { ThemeOptions } from '@mui/material';

const common: Omit<ThemeOptions, 'palette'> = {
	typography: {
		fontFamily: 'PT Sans Caption, sans-serif',
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: `
                @font-face {
                    font-family: 'work-sans';
                    font-style: normal;
                    font-display: swap;
                    font-weight: 400;
                    unicodeRange: 
                        U+0000-00FF, U+0131, U+0152-0153,
                        U+02BB-02BC, U+02C6, U+02DA, U+02DC,
                        U+2000-206F, U+2074, U+20AC, U+2122,
                        U+2191, U+2193, U+2212, U+2215, U+FEFF;
                }
            `,
		},
		MuiButton: {
			defaultProps: {
				size: 'medium',
				disableRipple: true,
				disableElevation: true,
				variant: 'contained',
			},
			styleOverrides: {
				root: {
					fontWeight: 600,
					'&.Mui-disabled': {
						backgroundColor: '#f2f5f9',
					}
				},
				outlinedSecondary: {
					backgroundColor: '#fff', 
					'&:hover': {
					  	color: '#fff',
						borderColor: '#fff',
						backgroundColor: '#0d53fc', 
					},
				},
			}
		},
		MuiFilledInput: {
			defaultProps: {
				margin: 'dense',
			},
		},
		MuiFormControl: {
			defaultProps: {
				margin: 'dense',
			},
		},
		MuiFormHelperText: {
			defaultProps: {
				margin: 'dense',
			},
		},
		MuiIconButton: {
			defaultProps: {
				size: 'medium',
				disableRipple: true,
			},
		},
		MuiInputBase: {
			defaultProps: {
				margin: 'dense',
				size: 'medium',
			},
			styleOverrides: {
				root: {
					backgroundColor: '#fff',
				},
				input: {
					height: '13px',
					color: '#030303'
				}
			},
		},
		MuiInputLabel: {
			defaultProps: {
				margin: 'dense',
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root : {
					color: '#fff'
				}
			}
		},
		MuiListItem: {
			defaultProps: {
				dense: true,
			},
		},
		MuiOutlinedInput: {
			defaultProps: {
				margin: 'dense',
			},
		},
		MuiFab: {
			defaultProps: {
				size: 'small',
			},
		},
		MuiTable: {
			defaultProps: {
				size: 'small',
			},
		},
		MuiTextField: {
			defaultProps: {
				variant: 'outlined',
				margin: 'dense',
				size: 'small',
			},
		},
		MuiToolbar: {
			defaultProps: {
				variant: 'dense',
			},
		},
		MuiSvgIcon: {
			defaultProps: {
				color: 'primary',
				fontSize: 'medium'
			},
		}
	},
	shape: {
		borderRadius: 8,
	},
};

export default common;
