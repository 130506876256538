import { useCallback, useEffect, useState } from 'react';
import { User } from '../../../utils/interfaces/user';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { invalidateUser, validateUser } from '../../../store/slices/utils';
import { LoginData, initialUserState, loginUser, logoutUser } from './index.actions';
import useLocalStorage from '../../use-local-storage';

export interface AuthReturnType {
	user: User;
	login: (user: LoginData) => void;
	logout: () => void;
	loading: boolean;
	message: string;
}

function useAuth(): AuthReturnType {
	const [user, setUser] = useLocalStorage<User>('user');
	const [message, setMessage] = useState<string>('');
	const [loading, setLoading] = useState<boolean>(false);
	const { isUserLoggedIn } = useAppSelector((state) => state.utils);
	const dispatch = useAppDispatch();

	useEffect(() => {
		!isUserLoggedIn && setUser(null);
	}, [isUserLoggedIn, setUser]);

	const login = useCallback(
		async (u: LoginData) => {
			setLoading(true);
			try {
				const response = await loginUser(u);
				if (typeof response !== 'string') {
					setUser(response.user);
					dispatch(validateUser());
					setMessage(response.message);
				} else {
					setMessage(response);
				}
			} catch (errorMessage) {
				console.error(errorMessage);
				setMessage('Failed to login');
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setUser, dispatch]
	);

	const logout = useCallback(
		async () => {
			setLoading(true);
			try {
				await logoutUser();
				setUser(initialUserState);
				dispatch(invalidateUser());
				setMessage('Logout successfuly');
			} catch (errorMessage) {
				console.error(errorMessage);
				setMessage('Failed to logout');
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setUser, dispatch]
	);

	return {
		user: user || initialUserState,
		loading,
		login,
		logout,
		message,
	};
}

export default useAuth;
