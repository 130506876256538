/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const utilsSlice = createSlice({
	name: 'utils',
	initialState: {
		isDrawerOpen: false,
		isUserLoggedIn: false,
		configId: ''
	},
	reducers: {
		validateUser: (state) => {
			state.isUserLoggedIn = true;
		},
		invalidateUser: (state) => {
			state.isUserLoggedIn = false;
		},
		toggleDrawer: (state, action) => {
			state.isDrawerOpen = action.payload;
		},
		setConfigId: (state, action) => {
			state.configId = action.payload;
		},
	},
});

export const { validateUser, invalidateUser, toggleDrawer, setConfigId } = utilsSlice.actions;
export default utilsSlice.reducer;
