import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ExpandLess, ExpandMore, HorizontalRule } from '@mui/icons-material';
import { useAppSelector } from '../../../../store/hook';
import { MenuItem } from '../menu-items';
import { uuid } from '../../../../utils/functions/util-funct';
import { menuIcons } from '../data/menuIcon.data';

interface IState {
	menuTab: MenuItem;
}

const MenuCollapsed: FC<IState> = ({ menuTab }) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const tempOpen = pathname.includes(menuTab.url) && menuTab.url !== '/';
	const [open, setOpen] = useState(tempOpen);
	const { isDrawerOpen } = useAppSelector((state) => state.utils);
	const handleClick = (): void => {
		const callbackNeeded = isDrawerOpen
			? (): void => setOpen((prev) => !prev)
			: (): void => menuTab?.children && navigate(menuTab?.children[0]?.url);
		callbackNeeded();
	};

	return (
		<>
			<ListItem
				onClick={handleClick}
				sx={{
					minHeight: 48,
					justifyContent: 'initial',
					pl: 2.5,
				}}
			>
				<ListItemIcon
					sx={{
						minWidth: 0,
						mr: 3,
						justifyContent: 'center',
					}}
				>
					{menuIcons[menuTab.icon] && (
						React.cloneElement(menuIcons[menuTab.icon], {
							color: pathname.includes(menuTab.url) && menuTab.url !== '/' ? 'primary' : 'inherit',
						})
					)}
				</ListItemIcon>
				{isDrawerOpen && <ListItemText primary={menuTab?.title} />}
				{isDrawerOpen &&
					(open ? <ExpandLess sx={{ color: '#696969' }} /> : <ExpandMore sx={{ color: '#696969' }} />)}
			</ListItem>
			<Collapse in={open} timeout='auto' unmountOnExit>
				<List component='li' disablePadding>
					{isDrawerOpen &&
						menuTab?.children?.map((child) => (
							<ListItemButton
								key={uuid()}
								sx={{ pl: 5 }}
								onClick={(): void => navigate(child?.url)}
								disableRipple
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: 2,
										justifyContent: 'center',
									}}
								>
									<HorizontalRule
										color={pathname.includes(child?.url) ? 'primary' : 'inherit'}
										fontSize='small'
									/>
								</ListItemIcon>
								{isDrawerOpen && (
									<ListItemText
										primary={child?.title}
										primaryTypographyProps={{ fontSize: '14px' }}
									/>
								)}
							</ListItemButton>
						))}
				</List>
			</Collapse>
		</>
	);
};

export default MenuCollapsed;
