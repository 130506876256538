import React from 'react';
import { User }  from '../../interfaces/user';
import { LoginData, initialUserState } from '../../../hooks/fetch-hooks/use-auth/index.actions';

export interface AuthContextType {
	user: User;
	login: (user: LoginData) => void;
	logout: () => void;
	loading: boolean;
	message?: string;
}
export const AuthContext = React.createContext<AuthContextType>({
	user: initialUserState,
	login: () => {},
	logout: () => {},
	loading: false,
});
