import { FC, Fragment } from 'react';
import { Divider } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hook';
import { toggleDrawer } from '../../../store/slices/utils';
import DrawerHeader from './drawer-header';
import MenuTabs from './menu-items';
import AdminMenu from './data/admin-menu.json';
import { Drawer, MenuDivider, MenuWrapper, SectionTitle } from './index.styled';
import { uuid } from '../../../utils/functions/util-funct';

export interface MenuItem {
	type?: string;
	title: string;
	icon: string;
	url: string;
	children?: MenuItem[];
}

const SideDrawer: FC = () => {
	const { isDrawerOpen } = useAppSelector((state) => state.utils);
	const dispatch = useAppDispatch();

	return (
		<Drawer variant='permanent' open={isDrawerOpen}>
			<DrawerHeader
				open={isDrawerOpen}
				closeDrawer={(): { payload: undefined; type: 'utils/toggleDrawer' } =>
					dispatch(toggleDrawer(!isDrawerOpen))
				}
			/>
			<Divider />
			{/* ****************| MENU ACTIONS |************************* */}
			<MenuWrapper>
				{AdminMenu.map(
					(el) =>
						(!!el.children?.length) && (
							<Fragment key={`menu-tabs-${uuid()}`}>
								{isDrawerOpen ? <SectionTitle>{el.title.toUpperCase()}</SectionTitle> : <MenuDivider />}
								<MenuTabs menuItems={el?.children || []} />
							</Fragment>
						)
				)}
			</MenuWrapper>
		</Drawer>
	);
};

export default SideDrawer;
