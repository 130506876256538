import { FC, lazy } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import Loadable from '../components/common/loadable';
import { AuthRoute, DashboardRoutes, RedirectToAuth } from '../components/routes';
import useAuth from '../hooks/fetch-hooks/use-auth';
import { AuthContext } from '../utils/contexts/auth';
// import { request } from '../utils/config/axios';

const SignIn = Loadable(lazy(() => import('../views/auth/sign-in')));
const Dashboard = Loadable(lazy(() => import('../views/common/dashboard')));
const RequestsPage = Loadable(lazy(() => import('../views/common/requests')));
const PlansPage = Loadable(lazy(() => import('../views/common/plans')));
const CreatePlanPage = Loadable(lazy(() => import('../views/common/plans/create-plan')));
const SiteConfigPage = Loadable(lazy(() => import('../views/common/site-configs')));
const EditConfigPage = Loadable(lazy(() => import('../views/common/site-configs/edit-config')));
const RequestsEditPage = Loadable(lazy(() => import('../views/common/requests/requests-edit')));
const RequestsAddPage = Loadable(lazy(() => import('../views/common/requests/requests-add')));
const AllModules = Loadable(lazy(() => import('../views/common/modules/all-modules')));
const AddModules = Loadable(lazy(() => import('../views/common/modules/add-modules')));
const AllThemes = Loadable(lazy(() => import('./common/themeConfig/all-theme')));
const AddThemes = Loadable(lazy(() => import('../views/common/themeConfig/create-theme')));
const EditThemes = Loadable(lazy(() => import('../views/common/themeConfig/edit-theme')));

const Views: FC = () => {
	const auth = useAuth();
	const isLoggedIn = auth.user?._id?.length > 0;

	// useBeforeUnload(
	// 	useCallback(() => {
	// 		isLoggedIn && request.post('auth/close');
	// 	}, [isLoggedIn])
	// );

	return (
		<AuthContext.Provider value={auth}>
			<HashRouter>
				<Routes>
					<Route path='/' element={<AuthRoute isLoggedIn={isLoggedIn} />}>
						<Route path='/' element={<RedirectToAuth />} />
						<Route path='/auth/sign-in' element={<SignIn />} />
					</Route>
					<Route path='/' element={<DashboardRoutes isLoggedIn={isLoggedIn} />}>
						<Route path='/dashboard' element={<Dashboard />} />
						<Route path='/requests' element={<RequestsPage />} />
						<Route path='/requests-add' element={<RequestsAddPage />} />
						<Route path='/requests-edit/:id' element={<RequestsEditPage />} />
						<Route path='/modules' element={<AllModules />} />
						<Route path='/modules-add' element={<AddModules />} />
						<Route path='/plans' element={<PlansPage />} />
						<Route path='/plans-add' element={<CreatePlanPage />} />
						<Route path='/configs' element={<SiteConfigPage />} />
						<Route path='/edit-configs' element={<EditConfigPage />} />
						<Route path='/themes' element={<AllThemes />} />
						<Route path='/themes-add' element={<AddThemes />} />
						<Route path='/themes/:id' element={<EditThemes />} />
					</Route>
				</Routes>
			</HashRouter>
		</AuthContext.Provider>
	);
};

export default Views;
