import { Theme, ThemeOptions, createTheme } from '@mui/material';

const darkTheme = (common: Omit<ThemeOptions, 'palette'>): Theme =>
	createTheme({
		...common,
		palette: {
			mode: 'light',
			primary: {
				main: '#0d53fc',
				light: '#106bff',
				dark: '#093ab0',
			},			  
			secondary: {
				main: '#3c539b',
				light: '#4e6bc9',
				dark: '#2a3a6c',
			},
			common: {
				black: '#282828',
				white: '#F6FAF8',
			},
			background: {
				default: '#1e3058',
				paper: '#f2f5f9',
			},
			text: {
				primary: '#FFF',
				secondary: '#030303',
				disabled: '#616161'
			},
			success: {
				main: '#1fd655'
			},
			error: {
				main: '#e3242b',
			}
		},
	});

export default darkTheme;
