import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { PageWrapper } from 'components/common/page-wrap/index.styled';
import { RouteProps } from '../index.interfaces';
import SideDrawer from '../../common/side-drawer';
import Header from '../../common/header';
import MainLayout from '../../common/main-layout';
import { useAppSelector } from '../../../store/hook';

export const DashboardRoutes: FC<RouteProps> = ({ isLoggedIn }) => {
	const { isDrawerOpen } = useAppSelector((state) => state.utils);

	if (isLoggedIn) {
		return (
			<>
				<Header />
				<MainLayout isDrawerOpen={isDrawerOpen} >
					<PageWrapper>
						<Outlet />
					</PageWrapper>
				</MainLayout>
				<SideDrawer/>
			</>
		);
	}

	return <Navigate to={'/'} replace />;
};

