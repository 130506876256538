import { FC, useContext } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { ExitToApp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../utils/contexts/auth';
import { useAppSelector } from '../../../store/hook';
import HeaderWrapper from './index.styled';
import Flex from '../../../components/common/flex';
import Logo from '../logo';

const Header: FC = () => {
	const { user, logout } = useContext(AuthContext);
	const { isDrawerOpen } = useAppSelector((state) => state.utils);
	const navigate = useNavigate();

	return (
		<HeaderWrapper isDrawerOpen={isDrawerOpen}>
			<Box onClick={(): void => navigate('/dashboard')} >
				<Logo />
			</Box>
			<Flex gap={2} className='header-text'>
				<Typography>Welcome {user?.email} !</Typography>
				<IconButton onClick={() => logout()} color="inherit"
					aria-label='Logout' aria-labelledby='Logout'>
					<ExitToApp />
				</IconButton>
			</Flex>
		</HeaderWrapper>
	);
};

export default Header;
