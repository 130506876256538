import { 
	Dashboard, PriorityHigh, Info, Description, 
	AssignmentTurnedIn, SettingsBrightnessOutlined,
	AccountTree,
	Contrast, 
} from '@mui/icons-material';

interface MenuIcons {
	[key: string]: JSX.Element; // Define the type of values as JSX.Element
}

export const menuIcons: MenuIcons = {
	dashboard_icon: <Dashboard />,
	priority_icon: <PriorityHigh />,
	info_icon: <Info/>,
	description_icon: <Description />,
	plans_icon: <AssignmentTurnedIn />,
	config_icon: <SettingsBrightnessOutlined />,
	module_icon: <AccountTree />,
	theme_icon: <Contrast />
};