const PING_URL = String(process.env.REACT_APP_PING_URL);
const API_URL = String(process.env.REACT_APP_API_URL);
const ADMIN_URL = String(process.env.REACT_APP_ADMIN_URL);
const GUESTS_URL = String(process.env.REACT_APP_API_GUESTS_URL);

const CRYPTO_KEY = String(process.env.REACT_APP_CRYPTO_JS_SK);

const APPLICATION_ID = String(process.env.REACT_APP_APPLICATION_ID);

const AUTH_KEY = String(process.env.REACT_APP_AUTH_KEY);

const config = {
	api: {
		ping: PING_URL,
		url: API_URL,
		adminUrl: ADMIN_URL,
		guestsUrl: GUESTS_URL,
		authKey: AUTH_KEY
	},
	crypto: {
		crypto_key: CRYPTO_KEY,
	},
	applicationId: APPLICATION_ID,
};

export default config;
