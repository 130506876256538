import React, { FC, ReactNode } from 'react';
import { styled, BoxProps, Box } from '@mui/material';

const Wrapper: FC<BoxProps> = styled(Box as FC<BoxProps>)(({ theme }) => ({
	display: 'flex',
	wikdth: '100%',
	height: '100vh',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: theme.palette.primary.main,
}));

interface SimpleLayoutProps {
    children: ReactNode;
};

const SimpleLayout: FC<SimpleLayoutProps> = ({ children }) => (
	<Wrapper>{children}</Wrapper>
);

export default SimpleLayout;