import axios, { AxiosInstance } from 'axios';
import store from '../../../store';
import { invalidateUser } from '../../../store/slices/utils';
import config from '../../../env.config';

const pingRequest: AxiosInstance = axios.create({
	baseURL: config.api.ping,
	withCredentials: true,
	headers: {
		authkey: config.api.authKey,
	}
});

const request: AxiosInstance = axios.create({
	headers: {
		authkey: config.api.authKey,
		'Cache-Control': 'no-cache',
	},
	baseURL: config.api.url,
	withCredentials: true,
});

const requestAdmin: AxiosInstance = axios.create({
	headers: {
		authkey: config.api.authKey,
		'Cache-Control': 'no-cache',
	},
	baseURL: config.api.adminUrl,
	withCredentials: true,
});

const requestGuests: AxiosInstance = axios.create({
	headers: {
		authkey: config.api.authKey,
		'Cache-Control': 'no-cache',
	},
	baseURL: config.api.guestsUrl,
	withCredentials: true,
});

const instances: AxiosInstance[] = [request, requestAdmin, requestGuests, pingRequest];

for (const instance of instances) {
	instance.interceptors.response.use(
		(res)=> {
			(res.status === 401 || res.status === 403) && store.dispatch(invalidateUser());
			return res;
		},
		(res) => {
			(res.response.status === 401 || res.response.status === 403) && store.dispatch(invalidateUser());
			return res.response;
		}
	);
}

export { request, requestAdmin, requestGuests, pingRequest };
