import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { RouteProps } from '../index.interfaces';
import SimpleLayout from '../../common/simple-layout';

const AuthRoute: FC<RouteProps> = ({ isLoggedIn }) => {
	if (!isLoggedIn) return (
		<SimpleLayout>
			<Outlet />
		</SimpleLayout>
	);

	return <Navigate to={'/dashboard'} replace />;
};
const RedirectToAuth: FC = () => <Navigate to='/auth/sign-in' replace />;

export { RedirectToAuth };
export default AuthRoute;
