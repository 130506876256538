import { AppBar as MuiAppBar, AppBarProps, styled, Toolbar } from '@mui/material';
import { FC, ReactNode } from 'react';
import { DRAWER_WIDTH, HEADER_HEIGHT } from '../../../utils/constants';

const drawerWidth = DRAWER_WIDTH;
const headerHeight = HEADER_HEIGHT;

interface CustAppBarProps extends AppBarProps {
	isDrawerOpen?: boolean;
}

const AppBar: FC<CustAppBarProps> = styled(MuiAppBar as FC<CustAppBarProps>, {
	shouldForwardProp: (prop: string) => prop !== 'isDrawerOpen',
})(({ theme, isDrawerOpen }) => ({
	display: 'flex',
	backgroundColor: theme.palette.background.paper,
	boxShadow: 'none',
	padding: '0',
	border: `1px solid ${theme.palette.divider}`,
	zIndex: theme.zIndex.drawer + 1,
	width: 'calc(100% - 65px)',
	height: `${headerHeight}px`,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(isDrawerOpen && {
		display: 'flex',
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
	'.header-text': {
		color: theme.palette.text.secondary
	}
}));

interface HeaderWrapperProps {
	isDrawerOpen: boolean;
	children?: ReactNode;
}

const HeaderWrapper: FC<HeaderWrapperProps> = ({ isDrawerOpen, children }) => (
	<AppBar position='fixed' isDrawerOpen={isDrawerOpen}>
		<Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: '30px' }}>
			{children}
		</Toolbar>
	</AppBar>
);

HeaderWrapper.defaultProps = {
	children: '',
};

export default HeaderWrapper;
