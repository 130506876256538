import { FC } from 'react';
import { Box, BoxProps, styled } from '@mui/material';
import { DRAWER_WIDTH } from '../../../utils/constants';

export const drawerWidth = DRAWER_WIDTH;

interface CustBoxProps extends BoxProps {
	isDrawerOpen: boolean;
}

const MainLayout: FC<CustBoxProps> = styled(Box as FC<CustBoxProps>, {
	shouldForwardProp: (prop: string) => prop !== 'isDrawerOpen',
})(({ theme, isDrawerOpen }) => ({
	marginLeft: '64px',
	marginTop: '64px',
	padding: theme.spacing(3, 6),
	width: 'calc(100% - 64px)',
	height: 'calc(100vh - 64px)',
	overflowY: 'auto',
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(isDrawerOpen && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

export default MainLayout;
