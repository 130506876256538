import './App.css';
import { FC, Suspense, useEffect, useMemo } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { AxiosError, isAxiosError } from 'axios';
import Views from './views';
import useThemeToggle from './hooks/use-theme-toggle';
import theme from './utils/config/theme';
import ThemeContext from './utils/contexts/theme';
import { pingRequest } from './utils/config/axios';

interface ErrorResponse {
	message: string;
	status: number;
  }

const App: FC = () => {
	const [themeMode, toggleThemeMode] = useThemeToggle();
	const themeContext = useMemo(() => ({ themeMode, toggleThemeMode }), [themeMode, toggleThemeMode]);

	useEffect(() => {
		const checkPing = async (): Promise<void> => {
		  try {
				await pingRequest.get('ping');
		  } catch (error) {
				if (isAxiosError(error)) {
					const axiosError = error as AxiosError<ErrorResponse>;
					if (axiosError.response && axiosError.response.status === 401) {
				  console.error('User is not logged in');
					} else {
				  console.error('Error occurred:', axiosError);
					}
				} else {
			  console.error('Error occurred:', error);
				}
		  }
		};
	  
		checkPing();
	  }, []);

	return (
		<Suspense fallback='Loading...'>
			<div className='App'>
				<ThemeProvider theme={theme[themeMode]}>
					<ThemeContext.Provider value={themeContext}>
						<CssBaseline />
						<Views />
					</ThemeContext.Provider>
				</ThemeProvider>
			</div>
		</Suspense>
	);
};

export default App;
